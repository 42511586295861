<style>
  .noshadow {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
  }
</style>
<script>
import {Game} from "@/enums";

import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {WorkerState} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    VclList,
    VclBulletList,
    //HalfCircleSpinner
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
	  async populateGameLabsVersions() {
		  try {
			  let url = new URL(`https://services.cftools.com/gamelabs/versions`);

			  let response = await fetch(url, {
				  method: 'GET'
			  });

			  if(response.ok) {
				  let data = await response.json();
				  this.gameLabsVersions = data;
			  }
		  } catch (error) {
			  console.log(`[ERROR] ${error}`);
		  }
	  },
    async getData() {
	    try {
		    let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/game-integration`);

		    let response = await fetch(url, {
			    method: 'GET',
			    credentials: 'include'
		    });

		    if(response.ok) {
			    let data = await response.json();
			    this.gameIntegration = data.integration;
					this.ready = true;
		    } else {
			    if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
			    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
		    }

	    } catch (error) {
		    this.error = true;
		    console.log(`[ERROR] ${error}`);
	    }
    },
  },
  mounted() {
		this.getData();
		this.populateGameLabsVersions();
  },
  data() {
    return {
			Game: Game,
      ready: false,
      error: false,
	    gameIntegration: null,
	    gameLabsVersions: {
				dayz: null
	    }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
	    <template v-if="gameIntegration === false">
		    <div class="row">
			    <div class="col justify-content-center">
				    <div  class="info-component text-center align-middle mt-auto mb-auto">
					    <div class="info-component-space">
						    <i class="fad fa-mobile-alt text-danger info-component-icon"></i>
					    </div>
					    <div class="row" style="margin-top: 20px;">
						    <div class="col-lg-12">
							    <h3>{{$t('server.gameintegration.error.title')}}</h3>
							    <h5 class="text-muted">{{$t('server.gameintegration.error.description')}}</h5>
							    <h6>
								    <marquee>soon™</marquee>
							    </h6>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
	    </template>
	    <template v-else>
		    <template v-if="!gameIntegration.available">
			    <div class="row">
				    <div class="col justify-content-center">
					    <div  class="info-component text-center align-middle mt-auto mb-auto">
						    <div class="info-component-space">
							    <img :src="gameIconLarge(gameIntegration.game)" alt="server game asset large" class="info-component-icon" height="72">
						    </div>
						    <div class="row" style="margin-top: 20px;">
							    <div class="col-lg-12">
								    <h3>{{$t('server.gameintegration.unsupported.title')}}</h3>
								    <h5 class="text-muted">{{$t('server.gameintegration.unsupported.description')}}</h5>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </template>
		    <template v-else>
			    <div class="row">
				    <div class="col">
					    <div class="card card-body">
						    <!-- Title -->
						    <div class="row">
							    <div class="col">
								    <h2 class="text-uppercase p-0">
									    <img :src="gameIconLarge(gameIntegration.game)" alt="server game asset large" width="32">
									    <span class="mt-3">
								    {{ gameToTitle(gameIntegration.game) }}
							    </span>
								    </h2>
							    </div>
						    </div>
						    <!-- Progress -->
						    <div class="row mt-4">
							    <div class="col">
								    <template v-if="[Game.DAYZ, Game.DAYZ_EXP].includes(gameIntegration.game)">
									    <ul class="verti-timeline list-unstyled">
										    <li class="event-list">
											    <div class="event-timeline-dot">
												    <template v-if="gameIntegration.status.active">
													    <i class="fad fa-check-double text-success"/>
												    </template>
												    <template v-else>
													    <i class="fad fa-arrow-right text-primary"/>
												    </template>
											    </div>
											    <div class="d-flex justify-content-start">
												    <div class="flex-grow-0">
													    <div>
														    <h5 class="font-size-14">{{$t('server.gameintegration.gamelabs.install.title')}}</h5>
														    <p class="text-muted">{{$t('server.gameintegration.gamelabs.install.description')}}</p>
													    </div>
												    </div>
												    <div class="me-3 ml-4 align-self-center">
													    <a class="btn btn-primary" :href="gameIntegration.requirements.source" target="_blank">
														    <h6 class="p-0 m-0 text-uppercase">
															    {{$t('server.gameintegration.gamelabs.prompt')}}
														    </h6>
													    </a>
												    </div>
											    </div>
										    </li>
										    <li class="event-list">
											    <div class="event-timeline-dot">
												    <template v-if="gameIntegration.status.active">
													    <i class="fad fa-check-double text-success"/>
												    </template>
												    <template v-else>
													    <i class="fad fa-arrow-right text-primary"/>
												    </template>
											    </div>
											    <div class="d-flex justify-content-start">
												    <div class="flex-grow-0">
													    <div>
														    <h5 class="font-size-14">{{$t('server.gameintegration.gamelabs.configure.title')}}</h5>
														    <p class="text-muted">{{$t('server.gameintegration.gamelabs.configure.description')}}</p>
													    </div>
												    </div>
											    </div>
										    </li>
										    <li class="event-list">
											    <div class="event-timeline-dot">
												    <template v-if="gameIntegration.status.active">
													    <i class="fad fa-check-double text-success"/>
												    </template>
												    <template v-else>
													    <i class="fad fa-dot-circle"/>
												    </template>
											    </div>
											    <div class="d-flex justify-content-start">
												    <div class="flex-grow-0">
													    <div>
														    <h5 class="font-size-14">{{$t('server.gameintegration.gamelabs.data.title')}}</h5>
														    <p class="text-muted">{{$t('server.gameintegration.gamelabs.data.description')}}</p>
													    </div>
												    </div>
												    <div class="me-3 ml-4 align-self-center" v-if="gameIntegration.status.active">
													    <template v-if="gameLabsVersions.dayz">
														    <small class="badge" :class="gameIntegration.status.version >= gameLabsVersions.dayz ? 'badge-light' : 'badge-warning'">
															    <h6 class="p-0 m-0 text-uppercase" :class="gameIntegration.status.version < gameLabsVersions.dayz ? 'text-black' : ''">
																    {{$t('server.gameintegration.gamelabs.data.version', {version: gameIntegration.status.version})}}
																		<template v-if="gameIntegration.status.version < gameLabsVersions.dayz">
																			{{$t('server.gameintegration.gamelabs.data.outdated') }}
																		</template>
															    </h6>
														    </small>
													    </template>
													    <template v-else>
														    <small class="badge badge-light">
															    <h6 class="p-0 m-0 text-uppercase">
																    {{$t('server.gameintegration.gamelabs.data.version', {version: gameIntegration.status.version})}}
															    </h6>
														    </small>
													    </template>
												    </div>
											    </div>
										    </li>
									    </ul>
								    </template>
								    <template v-else>
									    <div class="row">
										    <div class="col justify-content-center">
											    <div  class="info-component text-center align-middle mt-auto mb-auto">
												    <div class="info-component-space">
													    <i class="fad fa-check-double text-success info-component-icon"></i>
												    </div>
												    <div class="row" style="margin-top: 20px;">
													    <div class="col-lg-12">
														    <h3>{{$t('server.gameintegration.actionless.title')}}</h3>
														    <h5 class="text-muted">{{$t('server.gameintegration.actionless.description')}}</h5>
													    </div>
												    </div>
											    </div>
										    </div>
									    </div>
								    </template>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
		    </template>
	    </template>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
